import React, {useEffect, useState} from 'react';
import {Button, Card, CardActions, CardContent, Divider, Grid, Typography} from '@mui/material';
import api from '../../api';
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';
import {LoadingButton} from '@mui/lab';
import {Check, Clear, Delete, Edit} from '@mui/icons-material';
import {useContext} from 'react';
import {AuthContext} from '../../App';
import Slider from 'react-slick';
import {isAdmin} from '../../utils/Authentication';
import parse from 'html-react-parser';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';

const ViewInternalPostPage = props => {
	const language = localStorage.getItem('i18nextLng');
	const {t} = useTranslation();
	const history = useHistory();
	const authContext = useContext(AuthContext);
	const [loading, setLoading] = useState(true);
	const [notAllowed, setNotAllowed] = useState(false);

	const [data, setData] = useState({
		_id: '',
		swedishTitle: '',
		swedishSubtitle: '',
		swedishText: '',
		swedishQuote: '',
		englishTitle: '',
		englishSubtitle: '',
		englishText: '',
		englishQuote: '',
		showOnStartPage: true,
		link: '',
		date: '',
		year: '',
		pictureUrl: '',
		pictureDescriptionSwedish: '',
		createdDate: new Date(Date.now()),

		pictureDescriptionEnglish: '',
		pictures: [],
		published: false,
	});
	const settings = {
		dots: true,
		infinite: true,
		speed: 2000,
		slidesToShow: 1,
		arrows: false,
		autoplay: true,
		slidesToScroll: 1,
	};
	useEffect(() => {
		getPost();
	}, []);
	const getPost = () => {
		api
			.get('/post/internalpost/' + props.match.params.post_id)
			.then(response => {
				if (response.data.success === false) {
					document.title = 'RED Management • 404';
					setNotAllowed(true);
					setTimeout(() => {
						history.push('/');
					}, 2000);
				} else {
					setData(response.data);
					setLoading(false);
					document.title = 'Redigera nyhet: ' + response.data.swedishTitle;
				}
			})
			.catch(error => {
				console.error(error);
				setLoading(false);
			});
	};

	const uploadMainPhoto = () => {
		api
			.post('/')
			.then(response => console.log(response))
			.catch(error => console.error);
	};
	if (notAllowed)
		return (
			<Grid
				container
				sx={{
					margin: '0 auto',
					minHeight: '50vh',
					maxWidth: '1300px',
					padding: {xs: '7rem 1rem', sm: '8rem 1rem', md: '8rem 1rem', xl: '8rem 1rem'},
				}}
			>
				<Grid item xs={12} sx={{padding: '2rem 0'}}>
					Du har saknar behörighet för att komma åt denna sida. Du dirigeras om...
				</Grid>
			</Grid>
		);
	else {
		return (
			<Grid
				container
				sx={{
					margin: '0 auto',
					minHeight: '50vh',
					maxWidth: '1300px',
					padding: {xs: '7rem 1rem', sm: '8rem 1rem', md: '8rem 1rem', xl: '8rem 1rem'},
				}}
			>
				{loading ? (
					<Grid item xs={12} sx={{paddingTop: '5rem'}}>
						<LoadingComponent loadingText={'Laddar...'} />
					</Grid>
				) : (
					<React.Fragment>
						<Grid
							item
							xs={12}
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: {xs: '3rem', md: '5rem'},
							}}
						>
							<div>
								<Link to={'/internwebb'} style={{cursor: 'pointer'}}>
									<h3
										style={{fontWeight: 'normal', margin: 0, color: '#4cae52', display: 'inline'}}
									>
										Internwebb
									</h3>
								</Link>
								<div style={{padding: '0 1rem', display: 'inline'}}>/</div>
								<Link to={'/internwebb'} style={{cursor: 'pointer'}}>
									<h3
										style={{fontWeight: 'normal', margin: 0, color: '#4cae52', display: 'inline'}}
									>
										{data.year}
									</h3>
								</Link>
								<div style={{padding: '0 1rem', display: 'inline'}}>/</div>
								<h3 style={{fontWeight: 'normal', margin: 0, color: '#4cae52', display: 'inline'}}>
									{data.swedishTitle}
								</h3>
							</div>
							{isAdmin(authContext.state.email) && (
								<div
									style={{
										display: 'flex',
										justifyContent: 'right',
										gap: '10px',
										padding: '10px 0 0',
										flexDirection: 'row',
										flexWrap: 'wrap',
									}}
								>
									<Grid container>
										<Grid
											item
											xs={12}
											style={{
												padding: '0',
												display: 'flex',
												justifyContent: 'space-between',
												gap: '1rem',
											}}
										>
											{authContext.state.isAuthenticated && (
												<Button
													startIcon={<Edit />}
													onClick={() => history.push('/editinternal/' + data._id)}
													variant="contained"
													style={{
														background: '#4cae52',
														textTransform: 'none',
														fontFamily: 'Titillium Web',
													}}
												>
													Redigera
												</Button>
											)}
										</Grid>
									</Grid>
								</div>
							)}
						</Grid>
						<Grid item xs={12}>
							<Divider style={{width: '100%', color: 'gray', margin: '1rem auto'}} />
						</Grid>
						<Grid item xs={12} md={6} style={{paddingBottom: '1rem'}}>
							<Slider {...settings}>
								{data.pictures.length > 0 &&
									data.pictures.map(p => (
										<div key={p._id}>
											<img
												src={p.url}
												style={{width: '90%', cursor: 'pointer'}}
												alt={data.title}
												onClick={() => window.open(data.url, '_blank')}
											/>
											<div style={{padding: '10px 0'}}>
												<Typography variant="body1">{p.swedishDescription}</Typography>
											</div>
										</div>
									))}
							</Slider>
						</Grid>

						<Grid item xs={12} md={6} style={{padding: '10px'}}>
							<div style={{margin: 0, padding: 0}}>
								<h2 style={{color: '#4cae52', margin: 0, padding: 0}}>
									{data.createdDate.toString().slice(0, 10)}
								</h2>
								{data.swedishTitle !== '' && data.swedishTitle?.length > 0 && (
									<>
										<h3>{data.swedishTitle}</h3>
										<p style={{fontSize: '1.1rem'}}>{data.swedishSubtitle}</p>
									</>
								)}
								{data.swedishText !== null &&
									data.swedishText.length > 0 &&
									parse(data.swedishText)}
								{data.link.length > 0 && (
									<React.Fragment>
										Länk: <a href={data.link}>{data.link}</a>
									</React.Fragment>
								)}
							</div>
						</Grid>
					</React.Fragment>
				)}
			</Grid>
		);
	}
};
export default ViewInternalPostPage;
