import React, {useContext, useEffect} from 'react';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	Tooltip,
	Typography,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import headerImg from '../../assets/contact.jpg';

import axios from 'axios';
const VCard = require('vcard-creator').default;
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import {useState} from 'react';
import api from '../../api';
import {Check, Clear, Delete, Edit, QrCode} from '@mui/icons-material';
import QRCode from 'react-qr-code';
import redm_logo from '../../assets/redm.png';
import {LoadingButton} from '@mui/lab';
import {exportContact} from '../../services/VCardExportService';
import PageHeader from '../../layout/PageHeader';
import {Link} from 'react-router-dom';
import {AuthContext} from '../../App';
import EditContact from './EditContact';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
export default function ContactPage(props) {
	const language = localStorage.getItem('i18nextLng');
	const [exportingContact, isExportingContact] = useState(false);
	const [imageBase64, setImageBase64] = useState('');
	const {t} = useTranslation();
	const [data, setData] = useState({});
	const [showQrCode, setShowQrCode] = useState(false);
	const [os, setOs] = useState('');
	const authContext = useContext(AuthContext);
	const [editing, setEditing] = useState(false);

	const [loading, isLoading] = useState(true);
	useEffect(() => {
		getContact();
		const userAgent = window.navigator.userAgent;

		if (/Macintosh|Mac OS/i.test(userAgent)) {
			setOs('Mac');
		} else if (/iPhone|iPad|iPod/i.test(userAgent)) {
			setOs('iOS (iPhone/iPad/iPod)');
		} else if (/Android/i.test(userAgent)) {
			setOs('Android');
		} else if (/Windows/i.test(userAgent)) {
			setOs('Windows');
		} else {
			setOs('Unknown');
		}
	}, []);
	const getContact = () => {
		api
			.get('/person/getpersonbyemail/' + props.match.params.email)
			.then(response => {
				setData(response.data);
				document.title =
					'RED Management • ' + response.data.firstName + ' ' + response.data.lastName;

				isLoading(false);
			})
			.catch(error => {
				console.error(error);
				isLoading(false);
			});
	};
	return (
		<Grid container sx={{margin: '0 auto', maxWidth: '1300px', padding: {xs: 0, md: '0 10px'}}}>
			<PageHeader headerImg={headerImg} pageTitle={data.fullName || ''} />
			<Grid
				item
				xs={12}
				style={{display: 'flex', justifyContent: 'space-between', padding: '1rem 0'}}
			>
				<BreadCrumb mainLinkUrl={'/kontakt'} mainLinkWord={'Internwebb'} title={data.fullName} />

				{authContext.state.isAuthenticated && (
					<div>
						<Grid container>
							<Grid
								item
								xs={12}
								style={{
									padding: '0',
									display: 'flex',
									justifyContent: 'space-between',
									gap: '1rem',
								}}
							>
								<Button
									startIcon={<Edit />}
									onClick={() => {
										setEditing(true);
									}}
									variant="contained"
									style={{
										background: '#4cae52',
										textTransform: 'none',
										fontFamily: 'Titillium Web',
									}}
								>
									Redigera
								</Button>
							</Grid>
						</Grid>
					</div>
				)}
			</Grid>
			<Grid item xs={12} style={{margin: '0 auto', maxWidth: '1300px', padding: '1rem 1rem'}}>
				<Divider style={{width: '100%', color: 'gray', margin: '1rem auto 0'}} />
				{loading ? (
					<CircularProgress />
				) : (
					<Card sx={{width: '315px'}}>
						<CardMedia
							component="img"
							image={data.pictureUrl}
							alt={data.fullName}
							height="355px"
						></CardMedia>
						<CardContent style={{height: '355px', fontSize: '0.9rem'}}>
							<p style={{lineHeight: '3px', color: '#3EAC48', fontSize: '1.2rem'}}></p>
							<p> {language === 'sv' ? data.swedishText : data.englishText}</p>
							<p style={{lineHeight: '5px'}}>
								<strong>{t('contactpage.phone')}</strong>
							</p>
							<p style={{lineHeight: '3px'}}>
								<a href={data.tel}>{data.displayPhone}</a>
							</p>
							<p style={{lineHeight: '5px'}}>
								<strong>{t('contactpage.email')}</strong>
							</p>
							<p style={{lineHeight: '3px'}}>
								<a href={'mailto:' + data.email}>{data.email.replace('@', '[a]')}</a>
							</p>
						</CardContent>
						<CardActions sx={{display: 'flex', justifyContent: 'space-between'}}>
							<Tooltip title={t('contactpage.addtocontactstooltip')} placement="right">
								<LoadingButton
									loading={exportingContact}
									onClick={() => exportContact(isExportingContact, data)}
									sx={{textTransform: 'none'}}
									color="primary"
									variant="outlined"
									startIcon={<PersonAddAltIcon />}
								>
									{t('contactpage.addtocontacts')}
								</LoadingButton>
							</Tooltip>
							<Tooltip title={language === 'sv' ? 'Visa QR-kod' : 'Show QR code'}>
								<IconButton onClick={() => setShowQrCode(true)} size="large">
									<QrCode />
								</IconButton>
							</Tooltip>
						</CardActions>
					</Card>
				)}
			</Grid>
			{showQrCode && (
				<Dialog
					open={showQrCode}
					onClose={() => setShowQrCode(false)}
					sx={{textAlign: 'center', padding: '2rem'}}
				>
					<img src={redm_logo} width="150" style={{margin: '1rem auto 0'}} />
					<DialogTitle>{data.firstName + ' ' + data.lastName}</DialogTitle>

					<DialogContent sx={{textAlign: 'center', padding: '1rem'}}>
						<Typography variant="body1">{data.email}</Typography>
						<Typography variant="body1">{data.displayPhone}</Typography>
						<QRCode
							size={256}
							style={{height: 'auto', maxWidth: '100%', width: '100%', marginTop: '1rem'}}
							value={'https://redmanagement.se/kontakt/' + data.email}
							viewBox={`0 0 256 256`}
						/>
					</DialogContent>
				</Dialog>
			)}
			{editing && (
				<EditContact
					editing={editing}
					setEditing={setEditing}
					updateTable={true}
					setCreatingNew={false}
					selected={data}
					creatingNew={false}
					getContacts={getContact}
				/>
			)}
		</Grid>
	);
}
