import React, {useEffect, useState} from 'react';
import {Button, Card, CardActions, CardContent, Divider, Grid, Typography} from '@mui/material';
import api from '../../api';
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';
import {LoadingButton} from '@mui/lab';
import {Check, Clear, Delete, Edit} from '@mui/icons-material';
import {useContext} from 'react';
import {AuthContext} from '../../App';
import Slider from 'react-slick';
import {isAdmin} from '../../utils/Authentication';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import parse from 'html-react-parser';

const ViewPostPage = props => {
	const language = localStorage.getItem('i18nextLng');
	const {t} = useTranslation();
	const history = useHistory();
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [loadingDelete, setLoadingDelete] = useState(false);
	const authContext = useContext(AuthContext);
	const [data, setData] = useState({
		_id: '',
		swedishTitle: '',
		swedishSubtitle: '',
		swedishText: '',
		swedishQuote: '',
		englishTitle: '',
		englishSubtitle: '',
		englishText: '',
		englishQuote: '',
		showOnStartPage: true,
		link: '',
		date: '',
		year: '',
		pictureUrl: '',
		pictureDescriptionSwedish: '',
		pictureDescriptionEnglish: '',
		pictures: [],
		createdDate: new Date(Date.now()),
		published: false,
	});
	const settings = {
		dots: true,
		infinite: true,
		speed: 2000,
		slidesToShow: 1,
		arrows: false,
		autoplay: true,
		slidesToScroll: 1,
	};
	useEffect(() => {
		getPost();
	}, []);
	const getPost = () => {
		api
			.get('/post/post/' + props.match.params.post_id)
			.then(response => {
				setData(response.data);
				if (language === 'sv') {
					document.title = response.data.swedishTitle;
				} else {
					document.title = response.data.englishTitle;
				}
			})
			.catch(error => console.error(error));
	};

	const uploadMainPhoto = () => {
		api
			.post('/')
			.then(response => console.log(response))
			.catch(error => console.error);
	};
	const deletePost = post => {
		api
			.delete('/post/post/' + data._id)
			.then(() => {
				history.push('/nyheter');
			})
			.catch(error => {
				console.error(error);
			});
	};
	return (
		<Grid
			container
			sx={{
				margin: '0 auto',
				maxWidth: '1300px',
				minHeight: '50vh',
				padding: {xs: '4rem 1rem 0', sm: '8rem 1rem 0', md: '8rem 1rem 0', xl: '8rem 1rem 0'},
			}}
		>
			<Grid
				item
				xs={12}
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					marginTop: {xs: '1rem', md: '3rem'},
					flexWrap: 'wrap',
				}}
			>
				<BreadCrumb
					mainLinkUrl={'/nyheter'}
					mainLinkWord={t('topbar.news')}
					subLinkUrl={'/nyheter'}
					subLinkWord={data.year}
					title={language === 'sv' ? data.swedishTitle : data.englishTitle}
				/>
				{authContext.state.isAuthenticated && (
					<div>
						<Grid container>
							<Grid
								item
								xs={12}
								style={{
									padding: '0',
									display: 'flex',
									justifyContent: 'space-between',
									gap: '1rem',
								}}
							>
								{authContext.state.isAuthenticated && (
									<Button
										startIcon={<Edit />}
										onClick={() => history.push('/redigeranyhet/' + data._id)}
										variant="contained"
										style={{
											background: '#4cae52',
											textTransform: 'none',
											fontFamily: 'Titillium Web',
										}}
									>
										Redigera
									</Button>
								)}
							</Grid>
						</Grid>
					</div>
				)}
			</Grid>
			<Grid item xs={12}>
				<Divider style={{width: '100%', color: 'gray', margin: '1rem auto'}} />
			</Grid>
			<Grid item xs={12} md={6} style={{paddingBottom: '1rem'}}>
				{data.pictures.length > 0 && (

					<Slider {...settings}>

						{data.pictures.length > 0 &&
							data.pictures.map(p => (
								<div key={p._id}>
									<img
										src={p.url}
										style={{maxWidth: '90%', cursor: 'pointer'}}
										alt={data.title}
										onClick={() => window.open(data.url, '_blank')}
									/>
									<div style={{padding: '10px 0'}}>
										<Typography variant="body1">
											{language === 'sv' ? p.swedishDescription : p.englishDescription}
										</Typography>
									</div>
								</div>
							))}
					</Slider>
				)}
			</Grid>

			<Grid item xs={12} md={6} style={{padding: '10px'}}>
				{language === 'sv' ? (
					<div style={{margin: 0, padding: 0}}>
						<h2 style={{color: '#4cae52', margin: 0, padding: 0}}>
							{data.createdDate.toString().slice(0, 10)}
						</h2>
						{data.swedishTitle !== '' && data.swedishTitle?.length > 0 && (
							<>
								<h3>{data.swedishTitle}</h3>
								<p style={{fontSize: '1.1rem'}}>{data.swedishSubtitle}</p>
							</>
						)}
						{data.swedishText !== null && data.swedishText?.length > 0 && parse(data.swedishText)}
						{data.swedishQuote.length > 0 && (
							<p style={{color: '#4cae52', fontSize: '1.3rem'}}>
								<i>"{data.swedishQuote}"</i>
							</p>
						)}
						{data.link.length > 0 && (
							<React.Fragment>
								Länk: <a href={data.link}>{data.link}</a>
							</React.Fragment>
						)}
					</div>
				) : (
					<div style={{margin: 0, padding: 0}}>
						<h2 style={{color: '#4cae52', margin: 0, padding: 0}}>
							{data.createdDate.toString().slice(0, 10)}
						</h2>
						{data.englishTitle !== '' && data.englishTitle?.length > 0 && (
							<>
								<h3>{data.englishTitle}</h3>
								<p style={{fontSize: '1.1rem'}}>{data.englishSubtitle}</p>
							</>
						)}
						{data.englishText !== null && data.englishText?.length > 0 && parse(data.englishText)}
						{data.englishQuote.length > 0 && (
							<p style={{color: '#4cae52', fontSize: '1.3rem'}}>
								<i>"{data.englishQuote}"</i>
							</p>
						)}
						{data.link.length > 0 && (
							<React.Fragment>
								Link: <a href={data.link}>{data.link}</a>
							</React.Fragment>
						)}
					</div>
				)}
			</Grid>
		</Grid>
	);
};
export default ViewPostPage;
