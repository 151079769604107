import React, {useContext, useEffect, useState} from 'react';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import api from '../../api';
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';
import {Add, CameraAlt, Check, Clear, Delete, Edit} from '@mui/icons-material';
import {useRef} from 'react';

import {AuthContext} from '../../App';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import EditToolbar from '../../components/EditToolbar/EditToolbar';
import Pictures from '../../components/Pictures';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import TextEditor from '../../components/TextEditor/TextEditor';

const EditPostPage = props => {
	const language = localStorage.getItem('i18nextLng');
	const authContext = useContext(AuthContext);

	const {t} = useTranslation();
	const history = useHistory();
	const inputRef = useRef();
	const [loadingDelete, setLoadingDelete] = useState(false);
	const [photoThatIsBeingEdited, setPhotoThatIsBeingEdited] = useState('');
	const [confirmDelete, setConfirmDelete] = useState(false);
	const today = new Date();
	const yearToday = today.getFullYear();
	const [data, setData] = useState({
		_id: '',
		swedishTitle: '',
		swedishSubtitle: '',
		swedishText: '',
		swedishQuote: '',
		englishTitle: '',
		englishSubtitle: '',
		englishText: '',
		englishQuote: '',
		showOnStartPage: true,
		link: '',
		date: new Date(Date.now()),
		year: '',
		pictureUrl: '',
		pictureDescriptionSwedish: '',
		pictureDescriptionEnglish: '',
		createdDate: new Date(Date.now()),
		createdBy: '',
		edited: new Date(Date.now()),
		editedBy: '',
		pictures: [],
		published: false,
	});

	useEffect(() => {
		if (props.match.params.post_id === 'ny') {
			createNewPost();
		} else {
			getPost();
		}
	}, []);
	const getPost = () => {
		api
			.get('/post/post/' + props.match.params.post_id)
			.then(response => {
				setData(response.data);
				document.title = 'Redigera projekt: ' + response.data.swedishTitle;
			})
			.catch(error => console.error(error));
	};

	const handleChange = event => {
		if (event.target.value !== null) {
			const newData = {...data, [event.target.name]: event.target.value};
			setData(newData);
			updatePost(newData);
		}
	};

	const createNewPost = () => {
		api
			.post('/post/post', data)
			.then(response => {
				setData(response.data);
				history.replace({pathname: `/redigeranyhet/${response.data._id}`});
			})
			.catch(error => console.error(error));
	};
	const deletePhoto = photo => {
		api
			.delete('/post/deletephoto/' + data._id + '/' + photo._id)
			.then(() => {
				const newData = data.pictures.filter(p => p._id !== photo._id);
				setData({...data, pictures: newData});
			})
			.catch(error => console.error(error));
	};
	const deletePost = () => {
		api
			.delete('/post/post/' + data._id)
			.then(() => {
				history.push('/nyheter');
			})
			.catch(error => {
				console.error(error);
			});
	};
	const updatePost = newData => {
		api.put('/post/post/' + newData._id, newData).catch(error => {
			console.error(error);
		});
	};
	return (
		<Grid
			container
			sx={{
				margin: '0 auto',
				minHeight: '50vh',
				maxWidth: '1500px',
				padding: {xs: '7rem 1rem', sm: '8rem 1rem', md: '8rem 1rem', xl: '8rem 1rem'},
			}}
		>
			<Grid
				item
				xs={12}
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					marginTop: {xs: '1rem', md: '3rem'},
					flexWrap: 'wrap',
				}}
			>
				<BreadCrumb
					mainLinkUrl={'/nyheter'}
					mainLinkWord={t('topbar.news')}
					subLinkUrl={'/nyheter'}
					subLinkWord={data.year}
					title={language === 'sv' ? data.swedishTitle : data.englishTitle}
				/>

				<EditToolbar
					deleteItem={deletePost}
					item={data}
					setItem={setData}
					setConfirmDelete={setConfirmDelete}
					updateItem={updatePost}
					createdBy={data.createdBy}
					text="nyhet"
					viewUrl={'/nyheter/' + data._id}
				/>
			</Grid>
			<Grid item xs={12}>
				<Divider style={{width: '100%', color: 'gray', margin: '1rem auto'}} />
			</Grid>
			<Grid item xs={12}>
				<Pictures
					item={data}
					getData={getPost}
					setItem={setData}
					attributeName={'posts'}
					updateItem={updatePost}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<FormControl fullWidth margin="dense">
							<TextField
								label="Titel (sv)"
								type="text"
								autoComplete="off"
								value={data.swedishTitle}
								onChange={handleChange}
								name="swedishTitle"
								id="swedishTitle"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth margin="dense">
							<TextField
								label="Ingress (sv)"
								type="text"
								multiline
								autoComplete="off"
								minRows={2}
								value={data.swedishSubtitle}
								onChange={handleChange}
								name="swedishSubtitle"
								id="swedishSubtitle"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth margin="dense">
							<TextEditor
								text={data.swedishText}
								setText={newText => {
									if (newText !== null) {
										const newData = {...data, swedishText: newText};
										setData(newData);
										updatePost(newData);
									}
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth margin="dense">
							<TextField
								label="Citat (sv)"
								type="text"
								value={data.swedishQuote}
								autoComplete="off"
								multiline
								minRows={4}
								onChange={handleChange}
								name="swedishQuote"
								id="swedishQuote"
							/>
						</FormControl>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} md={6} style={{paddingLeft: '1rem'}}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<FormControl fullWidth margin="dense">
							<TextField
								label="Titel (en)"
								type="text"
								value={data.englishTitle}
								onChange={handleChange}
								autoComplete="off"
								name="englishTitle"
								id="englishTitle"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth margin="dense">
							<TextField
								label="Ingress (en)"
								type="text"
								multiline
								minRows={2}
								value={data.englishSubtitle}
								autoComplete="off"
								onChange={handleChange}
								name="englishSubtitle"
								id="englishSubtitle"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth margin="dense">
							<TextEditor
								text={data.englishText}
								setText={newText => {
									if (newText !== null) {
										const newData = {...data, englishText: newText};
										setData(newData);
										updatePost(newData);
									}
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth margin="dense">
							<TextField
								label="Citat (en)"
								type="text"
								value={data.englishQuote}
								onChange={handleChange}
								autoComplete="off"
								multiline
								minRows={4}
								name="englishQuote"
								id="englishQuote"
							/>
						</FormControl>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<FormControl fullWidth margin="dense">
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							disableToolbar
							format="YYYY-MM-DD"
							InputProps={{
								readOnly: true,
							}}
							slotProps={{
								textField: {
									helperText: 'Ändra publiceringsdatum',
								},
							}}
							KeyboardButtonProps={{
								'aria-label': 'Välj publiceringsdatum',
							}}
							onChange={newValue => {
								const newData = {
									...data,
									date: newValue.toISOString(),
									createdDate: newValue.toISOString(),
								};
								setData(newData);
								updatePost(newData);
							}}
							name="created"
							id="created"
							value={dayjs(data.date)}
						/>
					</LocalizationProvider>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<FormControl fullWidth margin="dense">
					<TextField
						label="Länk till extern källa (valfritt)"
						type="text"
						value={data.link}
						onChange={handleChange}
						autoComplete="off"
						name="link"
						id="link"
					/>
				</FormControl>
			</Grid>
		</Grid>
	);
};
export default EditPostPage;
